import { gifData, msgData } from "./data";
import "./App.css";
import Confetti from "react-dom-confetti";
import React, { useState, useEffect } from "react";
import Roll from "react-reveal/Roll";
const num = Math.floor(Math.random() * (gifData.length - 0) + 0);
const msgNum = Math.floor(Math.random() * (msgData.length - 0) + 0);
const config = {
  angle: 90,
  spread: 360,
  startVelocity: 30,
  elementCount: 100,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "15px",
  height: "15px",
  perspective: "1500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

function App() {
  const [gif] = useState(gifData[num]);

  const [toggleConfetti, setToggleConfetti] = useState(false);
  useEffect(() => {
    setToggleConfetti(true);
    setInterval(() => {
      setToggleConfetti(false);
      setTimeout(() => {
        setToggleConfetti(true);
      }, 500);
    }, 5000);
  }, []);
  function message() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlString = urlParams.toString();
    if (urlString.includes("n")) {
      let newUser = urlParams.get(`n`);
      let formatUser = newUser.toUpperCase()[0] + newUser.substring(1);
      const outputMsg = formatUser + ", " + msgData[msgNum];
      return outputMsg;
    } else {
      let formatMsg = msgData[msgNum].toString();
      let outputMsg = formatMsg[0].toUpperCase() + formatMsg.substring(1);
      return outputMsg;
    }
  }
  return (
    <>
      <div className="left-icon">
        <a href="https://www.sodiumhalogen.com?ref=awesome.sodiumalogen.com">
          <img
            src="https://uploads-ssl.webflow.com/5fb420dae10f58bd44595f1d/5fd904372e980edf265d3429_sh-favicon-256.png"
            width="100px"
            alt="Sodium Halogen Logo"
          />
        </a>
      </div>
      <div class="page">
        <h1>{message()}</h1>
        <Roll left>
          <img
            class="gif"
            onClick={() => {console.log("button click"); window.location.reload() }}
            src={gif.img}
            alt={gif.alt}
          />
        </Roll>
        <div className="confettiExplode">
          <Confetti active={toggleConfetti} config={config} />
        </div>
      </div>
    </>
  );
}
export default App;
