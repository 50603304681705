export const gifData = [
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/awesome-text.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/brantley-double-thumbs.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/bull-rider-wm.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/cool2_Barrett-m.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/cool2_Barrett.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/dance-booth-wm-20.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/gooder_Barrett.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/high-5.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/hugs.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/Jeremy_Callme.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/jeremy_salute_old_.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/Jeremy_You%27reawesome.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/oh-yeah.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/on_it.gif",alt: ''},
{img: "https://sh-awesome.s3.us-east-1.amazonaws.com/thumbs-up-brantley.gif",alt: ''},
// {img: "https://ucdd5f9b154c3ae1a552c8839d3b.previews.dropboxusercontent.com/p/orig/ABKOvwajX2e4KrsTB5_VhdlXx2xig4ElpXJjk0SL7w7RVa7J7xdRbY0GeXxlSmbOsVjFEFlmQ1G9dp5ax2xjOwlgf9cFgCR5K3i95WziJ_jbtjp0Bu8jT0-CoKgykU2wMeaaTmHm4IhVzD_U76ECPHxUs7MeGY4nLCOR9NwhA2IvjWMh2ZSUi0OrPtkS3L9DFC4ANyPpTJVoVkfwgT304QUKk3P5-9_vWj-SOUvBbhc90B5jwwd6ZOUpKVdffMXpLGkpwJBctsCKjjMG4HpPUeKEXdbLCd0H0oaj8B6ltWoUeENNxOBwwWv-Ae_Dtm1Zuu7nM_0Rxf2gdESpFS3JIiJfIq45DswHG-8rOkJH7AP57g/p.gif?fv_content=true&size_mode=5",alt: ''},
// {img: "https://uce90c7779cbb16084e82e627f35.previews.dropboxusercontent.com/p/orig/ABJ3YcLuR2-Y5JzNVbG6yE_dtMmOc3hU2XnurNyxDNIUtgdi85g6bcGp25zIzXrqivNcy3WuiDoP0-aXbKldi5jAJcHbUW-3xmJQKIAoTRKyYVV_dCoC2hgU5V97zrARtuIrCSNx6u5To6tFlbX_mJC3VBiCFGwFhzaac6DnJ5lHqnJQ1mjZtdf6UHt544itZEhKAdSAlqYB-T6Zw_1z6Kd8ADMvE_tE8SeuLea8_jypyEb8GvcdJQafROi3jMshktRsYG2dt5cLEpNz2H4Ldux7RFWLFquLk1KWtVAO9EJz4PwWbZqVbshNYhVeZah4CUi7nzDM7xfWDruKJz5ITc1XEpwX3MFbGLrl0R6e-e8JbA/p.gif?fv_content=true&size_mode=5",alt: ''},
// {img: "https://uc98876806411ea0b51388faef54.previews.dropboxusercontent.com/p/orig/ABKHmFmQHMSuO9nUzXbrP_QrfIvZLRDrHnDcvw56pi6cqMACmS3VPWjTwDoWDZLm3hYiGx3KiO0tDERrtduDxMh8gp6rOoyDzV6zkKUdpWEsZkmEaExDp1fOVdb38yT5BQohCYDbI2Ms7IC-OV6QOtkBgFPYK35YbdRZiPQcrKRKHzz8qrGWbjpqdqJUD4VV2PzIEHXnPMSAEAb1rvh1mGFIeSGUSs2luUKIvNRGv2Lg2UAAFl2shklTy7i7yc-MK_n0R3CLtsZtw5irk8bxhrKX-9ouyW5kDf3xpBURjjQrOdrk8EZOIk3xlJy_ssqbHQ2LiYx_UygwsBwoIwNydPPKQfqQtWGIMqa5dVnmocmneg/p.gif?fv_content=true&size_mode=5,alt: ''"},
    

];

export const msgData = [
    "you're awesome!",
    "you're the best!",
    "way to go!",
    "keep it up!",
    "glad to have you!",
    "great job!",
    "you're super!"
    
]

